import * as React from "react"

import Layout from "../layout/layout"
import Button from "../components/button"

import notFoundImage from "../images/404.svg"

const NotFoundPage = () => {
  return (
    <Layout title="404" description="404">
      <div className="content-container four04_container">
        <img src={notFoundImage} alt="not found" />
        <h3 className="oops">Oops!</h3>
        <p className="four04-content">
          It looks like you're trying to access a page that has been deleted or
          never even existed
        </p>
        <Button colorSchema="sec">Go to homepage</Button>
      </div>
    </Layout>
  )
}

export default NotFoundPage
